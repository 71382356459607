<template>
    <CContainer fluid>
        <CRow>
            <CCol class='col-12'>
                <CCard>
                    <CRow class='mt-3 ml-3 mr-3 justify-content-between'>
                        <router-link :to="'/Payroll'" tag='button' role='button' block class='btn btn-success mb-1 ml-1'>
                            Geri
                        </router-link>
                    </CRow>
                    <CCardHeader>
                        <strong>Randevu Detayları</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable :items="tableData" :fields="fields" tableFilter hover>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
import { AppointmentAdministration, TeacherAdministration } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";

export default {
    name: 'DetailPayroll',
    Vcomponents: {
        VueLoadingButton,
    },
    mounted() {
        this.loadDetailAppointment()
    },
    watch: {
    },
    data() {
        return {
            tableData: [],
            fields: [
                { key: "appointmentId", label: "ID" },
                { key: "studentName", label: "Student Name" },
                { key: "appointmentType", label: "Appointment Type" },
                { key: "mints", label: "Minutes" },
                { key: "startDateTime", label: "Start Date Time" },
                { key: "endDateTime", label: "End Date Time" },
                { key: "paid", label: "Paid" },
            ]
        }
    },
    computed: {
    },
    methods: {
        async loadDetailAppointment() {
            const id = parseInt(this.$route.params['id'])
            this.isListLoading = true;
            const result = await AppointmentAdministration.listPaidAppointmentDetail(id)
            let tableDataList = result.data;
            tableDataList.forEach(e => {
                if(e.paid == true){
                    e.paid = 'Paid'
                }else{
                    e.paid = 'Not Paid'
                }
                this.tableData.push(e);
            });
            console.log(result);
        },
    },
} 
</script>